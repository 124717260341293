


/* Section principale - Utilisée pour définir les marges et le padding de la section principale */
.text-section {
    padding: 50px 0; /* Ajoute un espacement vertical de 50px en haut et en bas */
}

/* Conteneur principal - Centre le contenu de la section et le limite à une largeur maximale */
.ab-container {
    display: flex; /* Active le mode Flexbox pour organiser les enfants */
    flex-direction: column; /* Aligne les enfants verticalement (en colonne) */
    align-items: center; /* Centre les enfants horizontalement */
    margin: 0 auto; /* Centre le conteneur horizontalement */
    padding: 0 50px; /* Ajoute un padding de 50px à gauche et à droite */
    max-width: 1200px; /* Limite la largeur maximale du conteneur */
    box-sizing: border-box; /* Inclut le padding et les bordures dans la largeur totale */
}

/* Contenu "À propos" - Gère l'alignement et le style général du texte */
.about-content {
    text-align: justify; /* Justifie le texte pour un alignement propre à gauche et à droite */
    line-height: 1.8; /* Augmente l'espace entre les lignes pour une meilleure lisibilité */
    color: #333; /* Définit la couleur du texte en gris foncé */
}

/* Titres - Style des titres de la section "À propos" */
.h3-about {
    margin-top: 20px; /* Ajoute un espace au-dessus du titre */
    color: white; /* Définit la couleur blanche pour le texte */
    font-family: 'Poppins', sans-serif; /* Police utilisée pour le texte */
    font-size: 2.5em; /* Définit une grande taille de police pour les titres */
    margin-bottom: 0.5em; /* Ajoute un espacement en dessous pour séparer du contenu suivant */
    text-align: center; /* Centre les titres horizontalement */
    text-transform: uppercase; /* Transforme le texte en majuscules */
    letter-spacing: 1px; /* Ajoute un espacement entre les lettres pour un effet visuel élégant */
}

/* Paragraphes - Style général des paragraphes dans la section "À propos" */
.p-about {
    margin-top: 15px; /* Ajoute un espace au-dessus des paragraphes */
    font-size: 2em; /* Augmente la taille de la police pour une meilleure lisibilité */
    font-family: 'Poppins', sans-serif; /* Police utilisée pour le texte */
}

.p-about strong{
    color:#8300e9;
}

/* Liste - Style pour les listes dans le contenu "À propos" */
.about-content ul {
    margin-top: 20px; /* Ajoute un espace au-dessus de la liste */
    padding-left: 40px; /* Ajoute un retrait à gauche pour les puces de liste */
}

/* Éléments de la liste - Style pour chaque élément de la liste */
.about-content ul li {
    margin-bottom: 10px; /* Ajoute un espacement entre les éléments de la liste */
    font-size: 2em; /* Augmente la taille de la police */
    font-family: 'Poppins', sans-serif; /* Police utilisée pour le texte */
    color: white; /* Définit une couleur blanche pour le texte des éléments */
}

/* Conteneur pour la section "À propos" - Utilise Flexbox pour organiser le contenu */
#about .ab-container {
    display: flex; /* Active le mode Flexbox */
    flex-direction: row; /* Aligne les éléments horizontalement */
    align-items: flex-start; /* Aligne les enfants en haut du conteneur */
    width: 100%; /* Le conteneur prend toute la largeur disponible */
    box-sizing: border-box; /* Inclut le padding dans la largeur totale */
    margin-bottom: 20px; /* Ajoute un espace sous le conteneur */
}

/* Contenu "À propos" - Gère l'espace à l'intérieur du conteneur */
#about .about-content {
    flex: 1; /* Permet au contenu de prendre tout l'espace disponible dans le conteneur */
    text-align: justify; /* Justifie le texte */
    margin-left: 150px;
    margin-right: 150px;

}

/* Style spécifique pour les paragraphes dans la section "À propos" */
#about .p-about {
    margin-top: 25px; /* Ajoute un espace au-dessus des paragraphes */
}

/* Media Queries pour les écrans avec une largeur maximale de 768px */
@media (max-width: 768px) {
    /* Section principale - Supprime le padding */
    .text-section {
        padding: initial; /* Rétablit la valeur par défaut du padding */
        margin-bottom: 50px;
    }
    /* Titres - Ajuste la taille de la police pour les petits écrans */
    .h3-about {
        font-size: 2.5em;
    }
    #about .ab-container {
        display: initial; /* Réinitialise à la valeur par défaut pour 'display' */
        flex-direction: initial; /* Réinitialise à 'row', la valeur par défaut pour 'flex-direction' */
        align-items: initial; /* Réinitialise à 'stretch', la valeur par défaut pour 'align-items' */
        padding: initial; /* Réinitialise à '0', la valeur par défaut pour 'padding' */
        box-sizing: initial; /* Réinitialise à 'content-box', la valeur par défaut pour 'box-sizing' */
        margin-bottom: initial; /* Réinitialise à '0', la valeur par défaut pour 'margin-bottom' */

    }


}

/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {
    /* Section principale - Supprime le padding */
    .text-section {
        padding: initial; /* Rétablit la valeur par défaut du padding */
    }
    /* Titres - Réduit la taille de la police pour les très petits écrans */
    .h3-about {
        font-size: 1.8em;
    }

    /* Conteneur principal - Réduit la largeur pour les petits écrans */
    #about .ab-container {
        width: 90%; /* Limite la largeur à 90% de l'écran */
        display: initial; /* Réinitialise à la valeur par défaut pour 'display' */
        flex-direction: initial; /* Réinitialise à 'row', la valeur par défaut pour 'flex-direction' */
        align-items: initial; /* Réinitialise à 'stretch', la valeur par défaut pour 'align-items' */
        padding: initial; /* Réinitialise à '0', la valeur par défaut pour 'padding' */
        box-sizing: initial; /* Réinitialise à 'content-box', la valeur par défaut pour 'box-sizing' */
        margin-bottom: initial; /* Réinitialise à '0', la valeur par défaut pour 'margin-bottom' */

    }

    /* Éléments de la liste - Réduit la taille et l'espacement */
    .about-content ul li {
        font-size: 1.7em; /* Réduit la taille de la police */
        line-height: 1.5em; /* Réduit la hauteur de ligne */
    }

    /* Paragraphes - Réduit la taille de la police */
    .p-about {
        font-size: 1.7em; /* Réduit la taille de la police */
    }
    /* Liste - Style pour les listes dans le contenu "À propos" */
    .about-content ul{
        padding-left: initial; /* Retire un retrait à gauche pour les puces de liste */
    }
    #about .about-content {
        flex: 1; /* Permet au contenu de prendre tout l'espace disponible dans le conteneur */
        text-align: justify; /* Justifie le texte */
        margin-left: 30px; /* Ajoute un retrait à gauche */
        margin-right: 30px; /* Ajoute un retrait à droite */
    }
}



