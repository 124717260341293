/* Style général du conteneur qui contient tous les projets */
.projects-container {
    display: flex; /* Utilise flexbox pour aligner les éléments horizontalement */
    align-items: center; /* Centre les projets verticalement */
    justify-content: center; /* Centre les projets horizontalement */
    position: relative; /* Permet de positionner les éléments enfants de manière absolue si nécessaire */
    width: 54%; /* Limite la largeur du conteneur à 50% de la largeur totale de la page */
    /* Centre le conteneur sur la page avec des marges égales à gauche et à droite */
    margin: 70px auto 0;
}

/* Style du conteneur des projets qui permet de les faire défiler */
.projects-slider {
    display: flex; /* Utilise flexbox pour aligner les projets en ligne */
    overflow: hidden; /* Masque les projets qui dépassent de la zone visible */
    width: 94%; /* Le slider occupe toute la largeur du conteneur parent */
}

/* Style des cartes de projets (chaque projet individuel) */
/* Style des cartes de projets (chaque projet individuel) */
.project-card {
    min-width: 100%; /* La carte prend toujours 100% de la largeur du conteneur */
    max-width: 100%; /* Empêche la carte d'être plus large que la zone visible */
    display: none; /* Masque tous les projets par défaut */
    margin-right: 20px; /* Ajoute de l'espace entre les cartes */
    background-color: black; /* Fond de la carte */
    border-radius: 10px; /* Arrondit les coins de la carte */
    overflow: hidden; /* Empêche le contenu de déborder */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Ajoute une ombre douce autour de la carte */
    border: 0.5px white solid; /* Bordure blanche pour la carte */
    height: 450px; /* Hauteur totale de la carte ajustée */
}

/* Classe active pour afficher la carte active (seulement un projet visible à la fois) */
.project-card.active {
    display: block; /* Affiche la carte qui a la classe 'active' */
}

/* Style des images des projets */
.project-image {
    width: 100%; /* L'image prend toute la largeur de la carte */
    height: 250px; /* Hauteur définie pour l'image */
    object-fit: cover; /* Remplit le conteneur sans déformation */
    object-position: top; /* Affiche la partie supérieure de l'image */
    background-color: white; /* Optionnel : ajoute un fond si l'image ne remplit pas tout */
}

/* Style de la section d'information sur le projet (titre, description, etc.) */
.project-info {
    padding: 15px; /* Ajoute un peu plus d'espace interne pour le texte */
    background-color: black; /* Couleur de fond */
    text-align: center; /* Centre le texte */
    height: calc(100% - 300px); /* Ajuste automatiquement à la hauteur restante */
    line-height: 1.2; /* Réduction de l'espacement entre les lignes */

}

/* Style du titre du projet */
.project-info h3 {
    font-size: 2rem; /* Taille du texte du titre */
    margin-bottom: 10px; /* Espace sous le titre */
    color:white;
}

/* Style de la description du projet */
.project-info p {
    font-size: 2rem; /* Taille du texte de la description */
    color:white; /* Couleur de texte gris clair pour la description */
}
.project-info p a{
    text-decoration: none;
    font-size: 2rem; /* Taille du texte de la description */
    color: white; /* Couleur de texte gris clair pour la description */
}

.projet-info li{
    list-style-type: none; /* Supprime les puces */
    padding: 0; /* Facultatif : supprime le retrait intérieur du <ul> */
    margin: 0; /* Facultatif : supprime le retrait extérieur du <ul> */
}
/* Style de la navigation avec les puces de navigation */
.project-navigation {
    position: absolute; /* Positionne la navigation de manière absolue par rapport au conteneur parent */
    top: 50%; /* Place les puces verticalement au centre du conteneur */
    right: 0; /* Place les puces à droite du conteneur */
    transform: translateY(-50%); /* Centre les puces verticalement en tenant compte de leur propre hauteur */
}

/* Style de la liste des puces de navigation */
ul {
    list-style-type: none !important; /* Supprime les puces avec priorité */
    padding: 0 !important; /* Supprime les espaces intérieurs si présents */
    margin: 0 !important; /* Supprime les espaces extérieurs si présents */
}

/* Style de chaque puce de navigation */
.project-navigation li {
    width: 10px; /* Largeur de chaque puce */
    height: 10px; /* Hauteur de chaque puce */
    margin: 5px 0; /* Espace entre chaque puce */
    border-radius: 50%; /* Rends les puces rondes */
    background-color: #ccc; /* Couleur de fond par défaut des puces (gris clair) */
    cursor: pointer; /* Change le curseur au survol pour indiquer que la puce est cliquable */
    transition: background-color 0.3s; /* Transition douce pour le changement de couleur des puces */
}

/* Style de la puce active qui représente le projet actuellement visible */
.project-navigation .bullet.active {
    background: #8300e9; /* Couleur de fond bleue pour la puce active */
}




/* Style de base pour les liens "styled-link" */
.styled-link {
    position: relative; /* Permet de positionner les éléments :before et :after par rapport au lien */
    display: inline-block; /* Rend chaque lien un bloc pour mieux gérer le positionnement */
    color: transparent; /* Rend le texte invisible initialement */
    -webkit-text-stroke: 1px white; /* Bordure blanche autour du texte */
    font-size: 4em; /* Ajuste la taille du texte selon les besoins */
    font-weight: 900; /* Poids du texte */
    text-decoration: none; /* Enlève le soulignement par défaut */
    line-height: 1.2em; /* Espacement vertical */
    transition: 0.5s; /* Transition fluide pour les changements de style */
    font-family: Poppins, serif; /* Police de caractères */
    margin-top:20px;

}

/* Effet au survol */
.styled-link:hover {
    color: white; /* Texte blanc au survol */
    -webkit-text-stroke: 1px black; /* Bordure noire autour du texte */
    transition: 0.5s; /* Transition fluide */
}

/* Pseudo-éléments pour l'effet de double texte */
.styled-link:before,
.styled-link:after {
    content: attr(links); /* Texte basé sur l'attribut "links" */
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
}

/* Effet spécifique pour :before au survol */
.styled-link:hover:before {
    color: #2f0059; /* Couleur violette pour :before */
    z-index: 1; /* Devant le texte principal */
    -webkit-text-stroke: 1px black; /* Bordure noire */
    transform: translate(10px, -10px); /* Décalage léger */
}

/* Effet spécifique pour :after au survol */
.styled-link:hover:after {
    color: #8300e9; /* Couleur violette foncée pour :after */
    z-index: 2; /* Devant le texte principal et :before */
    -webkit-text-stroke: 1px #000; /* Bordure noire */
    transform: translate(20px, -20px); /* Décalage plus marqué */
}



/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {

    /* Style général du conteneur qui contient tous les projets */
    .projects-container {
        width: 90%; /* Limite la largeur du conteneur à 50% de la largeur totale de la page */
        /* Centre le conteneur sur la page avec des marges égales à gauche et à droite */
        margin: 26px auto 0;
    }


    /* Style du conteneur des projets qui permet de les faire défiler */
    .projects-slider {
        display: initial; /* Utilise flexbox pour aligner les projets en ligne */
        overflow: initial; /* Masque les projets qui dépassent de la zone visible */

    }
    .project-card {
        min-width: initial; /* La carte prend toujours 100% de la largeur du conteneur */
        max-width: initial; /* Empêche la carte d'être plus large que la zone visible */
        height: 350px; /* Hauteur totale de la carte ajustée */
    }
    .project-info{
        padding: initial;
    }
    .styled-link{
        font-size: 2em;
    }
    .project-info p{
        font-size:1.2em;
    }
}