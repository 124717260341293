/* Base des styles */
.skills-css {
    display: flex;
    justify-content: center;
    align-items: center;
}

.skills-container {
    position: relative;
    width: 600px; /* Taille du cercle par défaut */
    height: 600px; /* Taille du cercle par défaut */
    border-radius: 50%;
    background: radial-gradient(circle, #8300e9 10%, #000000 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.skill {
    position: absolute;
    width: 100px; /* Taille des éléments skill par défaut */
    height: 100px; /* Taille des éléments skill par défaut */
    border-radius: 50%;
    background: linear-gradient(135deg, #8300e9, #2f0059);
    color: white;
    font-size: 1.5em; /* Taille du texte par défaut */
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transform-origin: 300px 300px; /* Ajuster le rayon par défaut */
}

/* Texte central */
.central-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em; /* Taille du texte central par défaut */
    color: white;
    font-weight: bold;
    text-align: center;
    z-index: 10;
    font-family: 'Poppins', sans-serif;
}

/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {
    .skills-container {
        width: 200px; /* Garder la taille du cercle à 200px pour les petits écrans */
        height: 200px; /* Garder la taille du cercle à 200px pour les petits écrans */
        margin-top: 50px;
    }

    .skill {
        width: 60px; /* Garder la taille des compétences à 40px */
        height: 60px; /* Garder la taille des compétences à 40px */
        font-size: 1em; /* Garder la taille du texte à 1em */
        transform-origin: 100px 100px; /* Garder le rayon à 100px */
    }

    .central-text {
        font-size: 1.2em; /* Garder la taille du texte central réduite */
    }
}
