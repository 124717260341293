body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}
/* Section Contact */
/* Style pour la section de contact, centrée et espacée */
#contact {
    padding: 20px; /* Espace intérieur */
    display: flex; /* Utilise Flexbox */
    flex-direction: column; /* Aligne les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    justify-content: center; /* Centre les éléments verticalement */
    height: 100vh; /* Prend toute la hauteur de la fenêtre */
    margin: 0; /* Supprime les marges autour */
    text-align: center; /* Centre le texte à l'intérieur */


}


/* Style pour les titres et les paragraphes */

#contact p{
    font-size: 2em;
    color: White;
    font-family: 'Poppins', sans-serif;
    width: 53%;
    margin-bottom: 50px;
}

/* Formulaire de contact */
#contact-form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centre les éléments horizontalement */
    justify-content: center; /* Centre les éléments à l'intérieur */
    width: 52%; /* Prend toute la largeur disponible */

    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

}

/* Styles pour les labels du formulaire */
#contact-form label {
    font-weight: bold; /* Rend le texte des labels en gras */
    margin-bottom: 5px; /* Ajoute de l'espace sous chaque label */
    font-size: 1.5em;
    font-family: 'Poppins', sans-serif;
}
/* Style pour les champs de texte et les zones de texte dans le formulaire */
#contact-form input,
#contact-form textarea {
    width: calc(100% - 22px); /* Ajuste la largeur pour compenser le padding et la bordure */
    padding: 10px; /* Ajoute de l'espace intérieur dans les champs */
    margin-bottom: 15px; /* Ajoute de l'espace sous chaque champ */
    border: 1px solid #ddd; /* Définit la couleur de la bordure des champs */
    border-radius: 4px; /* Arrondit les coins des champs */
}
/* Styles pour la zone de texte */
#contact-form textarea {
    resize: vertical; /* Permet le redimensionnement vertical de la zone de texte */
    min-height: 150px; /* Définit une hauteur minimale pour la zone de texte */
}
/* Styles pour le bouton de soumission */
#contact-form input[type="submit"] {
    background-color: black; /* Définit la couleur de fond du bouton */
    color: #fff; /* Définit la couleur du texte du bouton */
    border: none; /* Supprime la bordure du bouton */
    padding: 10px 20px; /* Ajoute de l'espace intérieur dans le bouton */
    font-size: 1.5em; /* Définit la taille de la police du bouton */
    cursor: pointer; /* Change le curseur au survol pour indiquer que le bouton est cliquable */
    border-radius: 4px; /* Arrondit les coins du bouton */
    align-self: center; /* Centre le bouton horizontalement */
    margin-top: 10px; /* Ajoute de l'espace au-dessus du bouton */
    font-family: 'Poppins', serif;
    font-weight: bold;
}
/* Change la couleur de fond du bouton lorsqu'on passe la souris dessus */
#contact-form input[type="submit"]:hover {
    background: linear-gradient(90deg, green, #8300e9); /* Dégradé de vert à violet */
    color: #fff; /* Texte blanc pour un meilleur contraste */
    transition: background 0.3s ease; /* Animation fluide lors du survol */
}
.confirmation-message {
    color: green; /* Définit la couleur du texte du message de confirmation */
    font-size: 16px; /* Définit la taille de la police du message */
    margin-top: 20px; /* Ajoute de l'espace au-dessus du message */
}
/* Section Contact */
.contact-section {
    position: relative; /* Assure que la section reste au-dessus */
    z-index: 1; /* Place la section au-dessus du fond */

}
/*--------------------------------------------------------------------------*/
/* Media Queries pour les écrans avec une largeur maximale de 768px */
@media (max-width: 768px) {
    /* Styles pour le titre de la section */
    #contact h1 {
        margin-top: 30px; /* Ajoute de l'espace au-dessus du titre */
        margin-bottom: 30px; /* Ajuste l'espace sous le titre */
    }
    /* Section Contact */
    #contact {
        padding: initial; /* Réinitialise le padding pour les petits écrans */
        align-items: center; /* Centre les éléments horizontalement */
    }
    /* Formulaire de contact */
    #contact-form {
        max-width: initial; /* Réinitialise la largeur maximale pour les petits écrans */
        width: 500px; /* Définit une largeur fixe pour le formulaire */
    }

    #contact p{
        font-size: 1.2em;

    }
}
/*--------------------------------------------------------------------------*/
/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {

    /* Section Contact */
    #contact {
        padding: initial; /* Réinitialise le padding pour les très petits écrans */
        align-items: center; /* Centre les éléments horizontalement */
        margin-top:10px;
    }
    /* Formulaire de contact */
    #contact-form {
        max-width: initial; /* Réinitialise la largeur maximale pour les très petits écrans */
        width: initial; /* Réinitialise la largeur pour les très petits écrans */
    }
    #contact p{

        width: 90%;
        margin-bottom: 0;
        margin-top: 0;
        font-size: 1.7em;
        text-align: justify;
    }

}

