/* Menu fixe : style général */
.fixed-menu {
    position: fixed; /* Le menu reste fixe par rapport au viewport */
    top: 50%; /* Position verticale centrée */
    left: 0; /* Position à gauche de l'écran */
    transform: translateY(-50%); /* Ajuste pour un alignement parfaitement centré */
    background-color: transparent; /* Fond transparent */
    padding: 30px; /* Espacement interne */
    border-radius: 5px; /* Coins légèrement arrondis */
    z-index: 1000; /* Assure que le menu est devant les autres éléments */
    max-width: 250px; /* Limite la largeur maximale */
    overflow: hidden; /* Cache le débordement du contenu */
    font-family: 'Poppins', sans-serif; /* Police utilisée pour le texte */
}

/* Suppression des puces et ajustement du padding de la liste */
.fixed-menu ul {
    list-style-type: none; /* Supprime les puces */
    padding: 0; /* Supprime l'espace interne supplémentaire */
}

/* Style de base pour les liens du menu */
.fixed-menu ul li a {
    position: relative; /* Position pour permettre l'utilisation des pseudo-éléments */
    display: block; /* Chaque lien occupe une ligne complète */
    color: transparent; /* Texte transparent pour utiliser uniquement le contour */
    -webkit-text-stroke: 1px #fff; /* Contour blanc pour le texte */
    font-size: 3.5em; /* Taille de police importante */
    font-weight: 900; /* Texte en gras */
    text-decoration: none; /* Supprime le soulignement par défaut */
    line-height: 1em; /* Hauteur de ligne ajustée */
    padding: 10px 0; /* Espacement vertical entre les liens */
}

/* Style au survol des liens */
.fixed-menu ul li a:hover {
    color: #fff; /* Texte blanc plein au survol */
    -webkit-text-stroke: 1px #000; /* Contour noir pour le texte */
    transition: 0.5s; /* Animation de transition douce */
}

/* Effets des pseudo-éléments :before et :after */
.fixed-menu ul li a:before,
.fixed-menu ul li a:after {
    content: attr(links); /* Texte des pseudo-éléments basé sur l'attribut links */
    position: absolute; /* Position absolue par rapport au lien */
    top: 0; /* Alignement en haut */
    left: 0; /* Alignement à gauche */
    opacity: 0; /* Cachés par défaut */
    transition: 0.5s; /* Transition douce lors des changements */
}

/* Effet au survol pour le pseudo-élément :before */
.fixed-menu ul li a:hover:before {
    opacity: 1; /* Rend visible */
    color: #2f0059; /* Couleur violet foncé */
    z-index: 1; /* Place derrière le texte principal */
    -webkit-text-stroke: 1px #000; /* Contour noir */
    transform: translate(10px, -10px); /* Décalage diagonal */
}

/* Effet au survol pour le pseudo-élément :after */
.fixed-menu ul li a:hover:after {
    opacity: 1; /* Rend visible */
    color: #8300e9; /* Couleur violet clair */
    z-index: 2; /* Place derrière :before */
    -webkit-text-stroke: 1px #000; /* Contour noir */
    transform: translate(20px, -20px); /* Décalage diagonal plus important */
}

/* Media Queries pour les écrans avec une largeur maximale de 768px */
@media (max-width: 768px) {

    /* Style adapté pour le menu fixe en haut de l'écran */
    .fixed-menu {
        position: fixed; /* Menu reste fixe */
        top: 0; /* Aligné en haut */
        width: 100vw; /* Prend toute la largeur de l'écran */
        transform: translateY(0); /* Supprime le centrage vertical */
        border-radius: 0; /* Supprime les coins arrondis */
        padding: 10px 0; /* Réduction du padding */
        max-width: none; /* Pas de limite de largeur */
        background-color: black; /* Fond noir */
    }


    /* Centrage des éléments dans la liste */
    .fixed-menu ul {
        text-align: center; /* Texte centré */
    }

    /* Les liens sont affichés en ligne */
    .fixed-menu li {
        display: inline-block; /* Alignement horizontal */
        margin: 0 15px; /* Espacement entre les liens */
    }
}

/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {

    /* Style pour les petits écrans */
    .fixed-menu {
        top: 0; /* Toujours en haut */
        width: 100vw; /* Prend toute la largeur */
        transform: translateY(0); /* Pas de transformation verticale */
        border-radius: 0; /* Pas de coins arrondis */
        padding: 32px 0; /* Réduction du padding */
        max-width: none; /* Pas de limite de largeur */
        background-color: black; /* Fond noir */
    }

    /* Réduction de la taille de la police pour les liens */
    .fixed-menu ul li a {
        font-size: 2.5em; /* Taille réduite pour petits écrans */
        padding:0 0;
    }

    /* Style pour chaque élément de liste dans le menu */
    .fixed-menu li {
        display: inline-block;
        margin: 0 10px; /* Réduction de l'espacement entre les éléments */
    }
}