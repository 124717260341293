/* ---- reset ---- */

/* Réinitialise les marges du corps pour une mise en page cohérente */
body,html {
    margin: 0; /* Supprime les marges par défaut */
    font: normal 75% 'Roboto Mono', monospace, sans-serif; /* Définit la police et la taille par défaut */
}

/* Assure que le canvas s'affiche correctement */
canvas {
    display: block; /* Évite les espaces sous le canvas */
    vertical-align: bottom; /* Aligne le canvas au bas de la ligne */
}

/* ---- particles.js container ---- */

/* Conteneur pour les particules, souvent utilisé pour les arrière-plans animés */
#particles-js {
    position: fixed; /* Fixe le conteneur à la fenêtre d'affichage */
    top: 0; /* Aligne le conteneur en haut */
    left: 0; /* Aligne le conteneur à gauche */
    width: 100%; /* Occupe toute la largeur de la fenêtre */
    height: 100%; /* Occupe toute la hauteur de la fenêtre */
    background-color: black; /* Couleur de fond si aucune image de fond n'est définie */
    background-repeat: no-repeat; /* Empêche la répétition de l'image de fond */
    background-size: cover; /* Couvre toute la surface du conteneur avec l'image de fond */
    background-position: center; /* Centre l'image de fond dans le conteneur */
    z-index: -1; /* Place les particules derrière le contenu principal */
}

/* ---- Styles pour le texte centré ---- */

/* Conteneur principal de l'application, centré verticalement et horizontalement */
.App {
    position: relative; /* Positionnement relatif pour permettre le positionnement absolu des éléments enfants */
    display: flex; /* Utilisation de Flexbox pour la mise en page */
    flex-direction: column; /* Aligne les éléments enfants en colonne */
    justify-content: center; /* Centre les éléments enfants verticalement */
    align-items: center; /* Centre les éléments enfants horizontalement */
    height: 100vh; /* Hauteur de la fenêtre d'affichage */
    text-align: center; /* Centre le texte à l'intérieur du conteneur */
    z-index: 1; /* Assure que le texte est au-dessus des particules */
}

/* Cache les statistiques des particules, si utilisé */
.count-particles, #stats {
    display: none; /* Masque ces éléments */
}

/* Styles pour les titres et les paragraphes */
p {
    font-size: 3.5em; /* Définit la taille de la police pour les titres et les paragraphes */
    color: white; /* Définit la couleur du texte */
    margin: 0; /* Supprime les marges par défaut pour un espacement cohérent */
}
h1{
    color: white;
    font-size: 3.5em;
}

/*--------------------------------------------------------------------------*/
/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {
    h1, p {
        font-size: 1.5em; /* Réduit la taille de la police pour les écrans plus petits */
    }
}
.content{
    margin-bottom: 50px;
}