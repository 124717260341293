

body {
    background-color: black;

}
/* Style pour la page*/
.about-container {
    display: flex; /* Utilise un conteneur flex pour gérer la disposition des sections */
    flex-direction: column; /* Aligne les sections verticalement, une après l'autre */
    width: 100%; /* Prend toute la largeur disponible */
    height: 100vh; /* Prend toute la hauteur visible de la fenêtre (viewport) */
    overflow-y: auto; /* Active le défilement vertical si le contenu dépasse la hauteur de la fenêtre */
    box-sizing: border-box; /* Inclut le padding dans la largeur et la hauteur totales */
}


/* Style pour chaque section */
.section {
    text-align: center; /* Centre le texte dans chaque section */

}




/*---------------------------------------------------*/


/*------------------------------------------------------------------------------*/

/* Centre la section des compétences horizontalement */
.competences-container {
    display: flex; /* Utilise Flexbox pour gérer la mise en page */
    justify-content: center; /* Centre horizontalement la section des compétences */
}




.section-footer {
    text-align: center;

}


/* Conteneur principal */
.sphere {
    position: relative;
    height: 100vh;
    display: flex; /* Utilise le modèle flexbox */
    flex-direction: row; /* Aligne les enfants en ligne */
    flex-wrap: wrap; /* Permet aux enfants de passer à la ligne si nécessaire */
    justify-content: center; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
    margin: auto;
    background: #000; /* Fond noir */
    font-size: calc(var(--_size) * 0.022); /* Taille de police dynamique */
    --_factor: min(1000px, 100vh); /* Facteur basé sur la hauteur ou largeur maximale */
    --_size: min(var(--_factor), 100vw); /* Détermine la taille basée sur la largeur/hauteur */
}

/* Animation pour l'effet de scale */
@keyframes onloadscale {
    24% { scale: 1; } /* Début sans changement */
    100% { scale: 1.02; } /* Agrandissement léger */
}

/* Animation pour l'apparition progressive */
@keyframes onloadopacity {
    24% { opacity: 0; } /* Invisible au début */
    100% { opacity: 1; } /* Devient totalement visible */
}

/* Conteneur arrière-plan */
.bg {
    width: 50vw;
    height: 50vw;
    position: absolute; /* Permet de superposer les sphères */
    top: 0;
    overflow: visible; /* Assurez-vous que les sphères ne soient pas cachées */
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
}

/* Enfants du fond */
.bg > div {
    position: absolute; /* Positionnement absolu */
    scale: 1.2; /* Agrandissement léger */
    opacity: 0.6; /* Transparence légère */
}
/*------*/
/* Premiere sphère*/
.bg > div:nth-child(1) {
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour l'effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);

    translate: 0 -70%; /* Position initiale vers le haut */
    animation: onloadbga 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation sphère */
@keyframes onloadbga {
    0% { translate: 0 -70%; opacity: 0.3; } /* Début en haut avec transparence */
    100% { translate: 0 -64%; opacity: 0.8; } /* Déplacement final vers le bas avec plus d'opacité */
}
/*------*/
/* Deuxième sphère */
.bg > div:nth-child(2) {
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour l'effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);

    translate: 0 70%; /* Position initiale vers le bas */
    animation: onloadbgb 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation Deuxième sphère */
@keyframes onloadbgb {
    0% { translate: 0 70%; opacity: 0.3; } /* Début en bas avec transparence */
    100% { translate: 0 64%; opacity: 0.8; } /* Déplacement final vers le haut avec plus d'opacité */
}
/*------*/
/* Troisième sphère */
.bg > div:nth-child(3) {
    width: 60%;
    height: 60%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour l'effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);


    translate: 190% 60%; /* Position initiale vers la droite */
    animation: onloadbgc 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la troisième sphère */
@keyframes onloadbgc {
    0% { translate: 190% 60%; opacity: 0.3; } /* Début sur la droite avec transparence */
    100% { translate: 184% 50%; opacity: 0.8; } /* Déplacement final avec plus d'opacité */
}
/*------*/
/* Quatrième sphère */
.bg > div:nth-child(4) {
    display:none;
    width:80%;
    height: 80%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);

    translate: -120% 120%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgd 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation Quatrième sphère */
@keyframes onloadbgd {
    0% { translate: -120% 120%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: -114% 115%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/*------*/
/* Cinquième sphère */
.bg > div:nth-child(5) {
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);

    translate: 120% 160%; /* Position encore plus à droite et plus bas */
    animation: onloadbge 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Cinquième sphère */
@keyframes onloadbge {
    0% { translate: 120% 160%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: 130% 170%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Sixiéme  sphère*/
.bg > div:nth-child(6) {
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);


    translate: -50% 230%; /* Position encore plus à droite et plus bas */
    animation: onloadbgf 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la sixieme sphère */
@keyframes onloadbgf {
    0% { translate: -50% 230%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: -50% 210%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Septième sphère */
.bg > div:nth-child(7) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);

    translate: 50% 300%; /* Position encore plus à droite et plus bas */
    animation: onloadbgg 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Septième sphère */
@keyframes onloadbgg {
    0% { translate: 50% 300%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: 50% 290%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Huitième sphère */
.bg > div:nth-child(8) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);

    translate: -90% 390%; /* Position encore plus à droite et plus bas */
    animation: onloadbgh 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Huitième sphère */
@keyframes onloadbgh {
    0% { translate: -90% 390%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: -90% 380%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Neuvième sphère */
.bg > div:nth-child(9) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);
    translate: 90% 400%; /* Position encore plus à droite et plus bas */
    animation: onloadbgi 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Neuvième sphère */
@keyframes onloadbgi {
    0% { translate: 90% 400%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: 90% 400%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Dixième sphère */
.bg > div:nth-child(10) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);
    translate: -20% 430%; /* Position encore plus à droite et plus bas */
    animation: onloadbgj 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Dixième sphère */
@keyframes onloadbgj {
    0% { translate: -20% 430%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: -20% 420%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Onzième sphère */
.bg > div:nth-child(11) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);
    translate: 90% 500%; /* Position encore plus à droite et plus bas */
    animation: onloadbgk 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Onzième sphère */
@keyframes onloadbgk {
    0% { translate: 90% 500%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: 90% 500%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Douzième sphère */
.bg > div:nth-child(12) {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Ombres pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);
    translate: -90% 570%; /* Position encore plus à droite et plus bas */
    animation: onloadbgl 1s ease-in-out forwards; /* Animation de translation */
}

/* Animation pour la Douzième sphère */
@keyframes onloadbgl {
    0% { translate: -90% 570%; opacity: 0.3; } /* Position de départ encore plus à droite et plus bas */
    100% { translate: -90% 560%; opacity: 0.8; } /* Déplacement encore plus bas et à droite */
}
/*------*/
/* Première petite sphère */
.bg > div:nth-child(13) {
    display:none;
    width: 50%;
    height: 50%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);

    translate: 260% 230%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgm 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Première petite sphère */
@keyframes onloadbgm {
    0% { translate: 260% 230%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: 260% 220%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/*------*/
/* Deuxième petite sphère */
.bg > div:nth-child(14) {
    width: 70%;
    height: 70%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des om6res internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);
    translate: 160% 510%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgn 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Deuxième petite sphère*/
@keyframes onloadbgn {
    0% { translate: 160% 510%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: 160% 500%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/*------*/
/* Troisième petite sphère */
.bg > div:nth-child(15) {
    width: 50%;
    height: 50%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);

    translate: -160% 680%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgo 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Troisième petite sphère */
@keyframes onloadbgo {
    0% { translate: -160% 680%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: -160% 670%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/*------*/
/* Quatrième petite sphère */
.bg > div:nth-child(16) {
    display: none;
    width: 50%;
    height: 50%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(0, 233, 131, 0.2), /* Couleur différente (vert) */
            inset 0 0 2em 0.4em rgba(0, 233, 131, 0.2),
            0 0 0.1em 0.1em rgba(0, 233, 131, 0.2),
            0 0 1em 0.4em rgba(0, 233, 131, 0.3);

    translate: -10% 1060%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgp 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Quatrième petite sphère */
@keyframes onloadbgp {
    0% { translate: -10% 1060%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: -10% 1050%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/*------*/
/* Cinquième petite sphère */
.bg > div:nth-child(17) {
    display:none;
    width: 50%;
    height: 50%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des ombres internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);

    translate: 90% 1260%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgq 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Cinquième petite sphère */
@keyframes onloadbgq {
    0% { translate: 90% 1260%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: 90% 1250%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
/* Media Queries pour les écrans avec une largeur maximale de 768px */

/* Deuxième petite sphère */
.bg > div:nth-child(18) {
    width: 100%;
    height: 100%;
    border-radius: 100em; /* Forme complètement arrondie */
    box-shadow: /* Crée des om6res internes et externes pour un effet lumineux */
            inset 0 0 4em 3em rgba(131, 0, 233, 0.2),
            inset 0 0 2em 0.4em rgba(131, 0, 233, 0.2),
            0 0 0.1em 0.1em rgba(131, 0, 233, 0.2),
            0 0 1em 0.4em rgba(131, 0, 233, 0.3);
    translate: 200% 480%; /* Position encore plus bas que la deuxième sphère */
    animation: onloadbgq 1s ease-in-out forwards; /* Animation de translation */
}
/* Animation pour la Deuxième petite sphère*/
@keyframes onloadbgq {
    0% { translate: 200% 480%; opacity: 0.3; } /* Départ bas et gauche avec transparence */
    100% { translate: 200% 470%; opacity: 0.8; } /* Déplacement légèrement vers le haut */
}
