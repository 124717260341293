
/* Texte d'en-tête */
.header-text-about {
    color: #c8c2bd; /* Couleur du texte */
    font-size: 4em; /* Taille du texte */
    text-align: center; /* Centre le texte */
    line-height: 1.0625; /* Hauteur de ligne */
    font-weight: 600; /* Épaisseur de la police */
    letter-spacing: -0.009em; /* Espacement réduit entre les lettres */
    font-family: 'Poppins', sans-serif; /* Police utilisée pour le texte */
}


/* Effet de glow (brillance) */
.glow-filter-about {
    position: relative; /* Position relative pour le positionnement absolu de l'enfant */
    display: inline-block; /* Garde le texte sur une seule ligne */
    scale: 1; /* Échelle par défaut */
    /* Animation d'agrandissement */
    animation: onloadscale 1s ease-out forwards;
}

.glow-filter-about::before {
    content: attr(data-text); /* Affiche le texte du data-text */
    position: absolute; /* Positionne le texte de l'effet */
    top: 0;
    left: 0;
    color: #fff; /* Couleur principale */
    opacity: 0; /* Invisible par défaut */
    filter: url(#glow-4); /* Applique un filtre pour un effet de glow */
    animation: onloadopacity 1s ease-out forwards; /* Animation d'apparition */
    pointer-events: none; /* Empêche l'interaction avec cet élément */
}

/* Animation pour l'effet de scale */
@keyframes onloadscale {
    24% { scale: 1; } /* Début sans changement */
    100% { scale: 1.02; } /* Agrandissement léger */
}

/* Animation pour l'apparition progressive */
@keyframes onloadopacity {
    24% { opacity: 0; } /* Invisible au début */
    100% { opacity: 1; } /* Devient totalement visible */
}


/* Media Queries pour les écrans avec une largeur maximale de 768px */
@media (max-width: 768px) {
    .header-text-about{
        margin-bottom: initial;
        margin-top: 100px;
        font-size: 3em; /* Taille du texte */
    }

}

/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {
    .header-text-about{
        margin-bottom: initial;
        font-size: 3em; /* Taille du texte */
    }
}