@font-face {
    font-family: 'Heading Now';
    src: url('https://fonts.vdhazel.com/headingnow/Heading-Now-Variable-Regular-trial.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
/* Classe pour le texte animé */
.animated-text {
    display: flex; /* Utilise le modèle flexbox pour l'alignement */
    justify-content: center; /* Centre le contenu horizontalement */
    align-items: center; /* Centre le contenu verticalement */
    font-size: 1.5rem; /* Définit la taille de police du texte */
    line-height: 3rem; /* Définit l'espacement vertical entre les lignes */
    color: White; /* Définit la couleur du texte en blanc */
}

/* Style appliqué à chaque lettre animée */
.letter {
    display: inline-block; /* Chaque lettre est un bloc en ligne, ce qui permet une animation individuelle */
    --wght: 100; /* Poids de la police (variation de la graisse) */
    --wdth: 85; /* Largeur de la police (variation de la largeur) */
    --opacity: 0.25; /* Opacité initiale de la lettre (faible pour l'effet de transition) */
    --letter-spacing: 0; /* Espacement entre les lettres */
    --font-size: 5vw; /* Taille de la police de la lettre (relative à la largeur de la fenêtre) */

    /* Définition des variations de la police en fonction des propriétés personnalisées */
    font-variation-settings: "wght" var(--wght), "wdth" var(--wdth); /* Applique les variations de graisse et de largeur de police */
    opacity: var(--opacity); /* Applique l'opacité de la lettre selon la variable définie */
    letter-spacing: var(--letter-spacing); /* Applique l'espacement entre les lettres selon la variable définie */
    font-size: var(--font-size); /* Applique la taille de la police selon la variable définie */

    /* Transition pour l'animation de la lettre */
    transition:
            font-variation-settings 1s ease-in-out, /* Transition de la variation de la police sur 1 seconde */
            opacity 1s ease-in-out, /* Transition de l'opacité sur 1 seconde */
            letter-spacing 2s ease-in-out, /* Transition de l'espacement des lettres sur 2 secondes */
            font-size 1s ease-in-out; /* Transition de la taille de la police sur 1 seconde */
}
/* Positionnement et style pour l'animation du logo "Mamsweb" */
.logo-mamsweb {
    font-family: "Heading Now", sans-serif; /* Police utilisée pour le texte */
    font-optical-sizing: auto; /* Ajuste la taille optique de la police si pris en charge */
    font-style: normal; /* Définit le style de police en normal */
    display: flex; /* Active le modèle de boîte flexible */
    align-items: center; /* Centre verticalement le contenu flex */
    justify-content: center; /* Centre horizontalement le contenu flex */
    text-align: center; /* Centre le texte dans l'élément */
    position: absolute; /* Positionne l'élément par rapport à son conteneur parent */
    top: 0; /* Aligne l'élément en haut de la page */
    left: 0; /* Aligne l'élément à gauche de la page */
    margin-top: 20px; /* Ajoute un espace de 20px au-dessus de l'élément */
    padding: 20px 40px 20px 20px; /* Ajoute des espaces internes autour de l'élément */
    color: #fff; /* Couleur blanche pour le texte */
    background-color: transparent; /* Fond transparent pour l'élément */
    z-index: 10; /* Assure que l'élément reste visible au-dessus des autres couches */
}
/* Media Queries pour les écrans avec une largeur maximale de 768px */
@media (max-width: 768px) {
    /* Classe pour le texte animé */
    .animated-text {
        padding-left:5px;
        padding-right:5px;
        background-color: black; /* Définit un fond noir pour l'élément */
        margin-top: 50px; /* Ajoute un espace de 30px au-dessus de l'élément */
    }
    /* Positionnement et style pour l'animation du logo "Mamsweb" */
    .logo-mamsweb{
        padding:initial;
        margin-top: 40px; /* Ajoute un espace de 30px au-dessus de l'élément */
    }
}
/* Media Queries pour les écrans avec une largeur maximale de 480px */
@media (max-width: 480px) {
    /* Classe pour le texte animé */
    .animated-text {
        padding-left:5px;
        background-color: black; /* Définit un fond noir pour l'élément */
        margin-top: 40px; /* Ajoute un espace de 30px au-dessus de l'élément */
    }
    /* Positionnement et style pour l'animation du logo "Mamsweb" */
    .logo-mamsweb{
        padding:initial;
    }
}